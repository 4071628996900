import { RegistrationSetsService } from '../shared/services/registration-sets.service';
import { RegistrationSet } from '../shared/model/registration-set.model';
import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RegistrationDetailsComponent } from '../details/registration/registration-details.component';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { RegistrationsService } from '../shared/services/registrations.service';
import { SelectedSpaceService } from '../shared/services/selected-space.service';
import { ProgramsService } from '../shared/services/programs.service';
import { PortfoliosService } from '../shared/services/portfolios.service';
import { EquipmentService } from '../shared/services/equipment.service';
import { TranslateService } from '@ngx-translate/core';
import { DeleteDialogComponent } from '../dialogs/delete/delete-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { OrganizationsService } from '../shared/services/organizations.service';
import { RegistrationDetailsService } from '../shared/services/registration-details.service';
import { Registration } from '../shared/model/registration.model';
import { TransformRulesService } from '../shared/services/transform-rules.service';
import { TransformRule } from '../shared/model/transform-rule.model';
import { TelemetryPointService } from '../shared/services/telemetry-point.service';
import { Subscription } from 'rxjs';
import { FormValidatorService } from '../shared/services/form-validator.service';
import { BaselinePointService } from '../shared/services/baseline-point.service';
import { DrTypeService } from '../shared/services/dr-type.service';
import { RegistrationSetDetailsComponent } from '../details/registration-set/registration-set-details.component';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import {RegistrationDialogComponent} from "../dialogs/registration/registration-dialog.component";
import {FlexibleAssetsService} from "../shared/services/flexible-assets.service";

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss', '../shared/shared.styles.scss'],
})
export class EditComponent implements OnInit, OnDestroy {
  @ViewChild('edit') form: UntypedFormGroup;
  SUCCESS = 'Created Successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  NOT_CREATED = 'Oops, There was a problem creating your registration';
  REG_SET_NOT_UPDATED = 'Oops, There was a problem updating your registration set';
  REG_NOT_UPDATED = 'Oops, There was a problem updating your registration';
  REQUIRED = 'required';
  CONFLICT = 'There is a data conflict with this registration';
  TELEMETRY_POINTS_CREATED = 'Successfully updated';
  TELEMETRY_POINTS_FAILED = 'Failed to update';
  TELEMETRY_POINTS = 'Telemetry Points';
  BASELINE_POINTS_CREATED = 'Successfully updated';
  BASELINE_POINTS_FAILED = 'Failed to update/delete 1 or more Baseline Points';
  BASELINE_POINTS = 'Baseline Points';
  FORBIDDEN_EDIT_FIELDS = 'You are unable to edit one or more fields on this page';
  BASELINE_POINTS_FAILED_DETAILED = 'Baseline Points were generated | Please create points manually and review the baseline definition';
  loading = false;
  registration: Registration;
  appData: any = {
    isFullReg: null,
  };

  transformRules: TransformRule[];
  availabilityPoints: { id: string; display_label: string }[];
  org = '';
  regId = '';
  readonly mode = 'edit';
  saving = false;
  selectedDRType: any;

  registrationSet: RegistrationSet;

  private registrationComponentView: RegistrationDetailsComponent;
  private registrationSetComponentView: RegistrationSetDetailsComponent;
  private subscriptions: Subscription[] = [];

  @ViewChild(RegistrationDetailsComponent)
  set registrationComponent(registrationDetailsComponent: RegistrationDetailsComponent) {
    this.registrationComponentView = registrationDetailsComponent;
  }

  get registrationComponent() {
    return this.registrationComponentView;
  }

  @ViewChild(RegistrationSetDetailsComponent)
  set registrationSetComponent(registrationSetDetailsComponent: RegistrationSetDetailsComponent) {
    this.registrationSetComponentView = registrationSetDetailsComponent;
  }

  get registrationSetComponent() {
    return this.registrationSetComponentView;
  }

  get displayLabel() {
    if (this.selectedDRType.name === 'Registration') {
      if (
        this.registrationComponentView &&
        this.registrationComponentView.registration &&
        this.registrationComponentView.registration.name
      ) {
        return this.registrationComponentView.registration.name;
      }
    } else {
      if (
        this.registrationSetComponentView &&
        this.registrationSetComponentView.registrationSet &&
        this.registrationSetComponentView.registrationSet.displayLabel
      ) {
        return this.registrationSetComponentView.registrationSet.displayLabel;
      }
    }
    return '';
  }

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: GlobalAlertService,
    private registrationsService: RegistrationsService,
    private registrationDetailsService: RegistrationDetailsService,
    private registrationSetService: RegistrationSetsService,
    private organizationsService: OrganizationsService,
    private flexibleAssetsService: FlexibleAssetsService,
    private programsService: ProgramsService,
    private portfolioService: PortfoliosService,
    private equipmentService: EquipmentService,
    private translateService: TranslateService,
    private transformRulesService: TransformRulesService,
    private telemetryPointService: TelemetryPointService,
    private selectedSpaceService: SelectedSpaceService,
    private orgSelectorService: ContextSelectorService,
    private formValidatorService: FormValidatorService,
    private baselinePointService: BaselinePointService,
    private cdRef: ChangeDetectorRef,
    private drTypeService: DrTypeService,
  ) {
    const routeSub = this.route.params.subscribe(async params => {
      if (params.id) {
        const { id } = params;
        this.regId = id;
      }
    });

    const selectedDRTypeSub = this.drTypeService.selectedDRType$.subscribe(async currentDrType => {
      if (currentDrType) {
        this.selectedDRType = currentDrType;
        this.loading = true;
        if (this.selectedDRType.name === 'RegistrationSet') {
          try {
            await this.registrationSetService.getRegistrationSet(this.regId);
          } catch (err) {
            this.handleError();
          }
        } else {
          this.transformRules = null;
          this.registrationDetailsService.selectedRegistration.next(this.regId);
          await this.registrationDetailsService.getRegistrationDetails(this.regId);
        }
      }
    });

    const registrationSub = this.registrationDetailsService.registration$.subscribe(async (reg: Registration) => {
      if (reg && this.selectedDRType.name === 'Registration') {
        this.registration = reg;
        const { flexibleAssetId, sdpId } = reg;
        Promise.all([
          this.transformRulesService.getRules(reg.id),
          this.registrationsService.getRegistrationPoints(reg.id),
        ]).then(([rules, pointResp]) => {
          this.transformRules = rules;
          this.availabilityPoints = pointResp.data;
        });

        try {
          if (flexibleAssetId && this.organizationsService.isInit) {
            const orgId = await this.registrationsService.getOrgFromAsset(flexibleAssetId);
            this.organizationsService.getOrgs(orgId);
          } else if (sdpId && this.organizationsService.isInit) {
            const orgId = await this.registrationsService.getOrgFromSdp(sdpId);
            this.organizationsService.getOrgs(orgId);
          } else {
            // fallback in case no FA or SDP
            this.organizationsService.getOrgs();
          }
        } catch (err) {
          // fallback in case FA or SDP fail
          this.organizationsService.getOrgs();
        }

        this.loading = false;
        this.formValidatorService.triggerValidation(this.form);
      }
    });

    const registrationSetSub = this.registrationSetService.registrationSet$.subscribe(
      async (regSet: RegistrationSet) => {
        if (regSet) {
          this.registrationSet = regSet;
          this.loading = false;
          this.organizationsService.getOrgs();
        }
      },
    );

    this.translateService.get('registration.notification.updated_successfully').subscribe((result: string) => {
      this.SUCCESS = result;
      this.BAD_REQUEST = this.translateService.instant('registration.notification.bad_request');
      this.NOT_CREATED = this.translateService.instant('registration.notification.not_created');
      this.REG_SET_NOT_UPDATED = this.translateService.instant('registration_set.notification.not_updated');
      this.REG_NOT_UPDATED = this.translateService.instant('registration.notification.not_updated');
      this.REQUIRED = this.translateService.instant('registration.validation.required');
      this.CONFLICT = this.translateService.instant('registration.notification.conflict');
      this.TELEMETRY_POINTS_CREATED = this.translateService.instant('telemetry_point.edit.success');
      this.TELEMETRY_POINTS_FAILED = this.translateService.instant('telemetry_point.edit.failed');
      this.TELEMETRY_POINTS = this.translateService.instant('telemetry_point.edit.points');
      this.BASELINE_POINTS_CREATED = this.translateService.instant('baseline_point.edit.success');
      this.BASELINE_POINTS_FAILED = this.translateService.instant('baseline_point.edit.failed');
      this.BASELINE_POINTS = this.translateService.instant('baseline_point.edit.points');
      this.BASELINE_POINTS_FAILED_DETAILED = this.translateService.instant('baseline_point.create.notification.failed');
    });

    const conflictErrorSub = this.registrationsService.conflictError$.subscribe(() => {
      this.messageService.setWarning(this.CONFLICT, 7000);
    });

    const sitesSub = this.selectedSpaceService.sites$.subscribe(sites => {
      this.appData.sites = sites || [];
      this.appData.loadingSites = false;
    });

    const flexibleAssetsSub = this.flexibleAssetsService.flexibleAssets$.subscribe(flexibleAssets => {
      this.appData.flexibleAssets = flexibleAssets;
      this.appData.loadingAssets = false;
    });

    const programsSub = this.programsService.programs$.subscribe(programs => {
      this.appData.programs = programs || [];
      this.appData.loadingPrograms = false;
    });

    const productsSub = this.programsService.products$.subscribe(products => {
      this.appData.products = products || [];
      this.appData.loadingProducts = false;
    });

    const portfoliosSub = this.portfolioService.portfolios$.subscribe(portfolios => {
      this.appData.portfolios = portfolios || [];
      this.appData.loadingPortfolios = false;
    });

    const controlTypesSub = this.equipmentService.controlTypes$.subscribe(controlTypes => {
      this.appData.controlTypes = controlTypes;
      this.appData.loadingControlTypes = false;
    });

    const contextSub = this.orgSelectorService.currentContext$.subscribe(async (orgs: Context[]) => {
      this.appData.loadingSites = true;
      this.appData.sites = [];
      const org = orgs[0];
      this.org = org.id;
      this.loadDynamicLists(org.id);
    });

    this.appData.loadingPrograms = true;
    this.programsService.fetchPrograms();
    this.appData.loadingControlTypes = true;
    this.equipmentService.setControlTypes();

    this.subscriptions.push(
      ...[
        routeSub,
        selectedDRTypeSub,
        registrationSub,
        registrationSetSub,
        conflictErrorSub,
        sitesSub,
        flexibleAssetsSub,
        programsSub,
        productsSub,
        controlTypesSub,
        portfoliosSub,
        contextSub,
      ],
    );
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({});
  }
  ngAfterViewInit() {
    if (this.telemetryPointService.hasBrokenPoints) {
      this.messageService.setError(this.TELEMETRY_POINTS_FAILED);
    }
    if (this.baselinePointService.hasBrokenBaselinePoints) {
      this.messageService.setError(this.BASELINE_POINTS_FAILED);
    }
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  async handleSubmit() {
    if (!this.form.valid) {
      this.messageService.setError(this.REQUIRED);
    } else {
      let savingDialog: MatDialogRef<RegistrationDialogComponent, any>;
      this.saving = true;
      try {
        let response;
        if (this.selectedDRType.name === 'Registration') {
          savingDialog = this.openUpdateDialog();
          savingDialog.componentInstance.changeState('SAVING_REGISTRATION');
          const { registration, points, baselinePoints } = this.registrationComponentView;
          const formattedReg = registration;
          formattedReg.productId = registration.productId.id;
          response = await this.registrationsService.updateRegistration(formattedReg);

          if (this.transformRules) {
            await this.transformRulesService.save(this.regId, this.transformRules);
          }

          if (points) {
            await this.telemetryPointService.createOrUpdatePoints(this.regId, points);
          }

          if (baselinePoints) {
            await this.baselinePointService.createOrUpdateBaselinePoints(this.regId, baselinePoints);
          }
        } else if (this.selectedDRType.name === 'RegistrationSet') {
          const { registrationSet, registrationsToRemove } = this.registrationSetComponent;
          response = await this.registrationSetService.updateRegistrationSet(registrationSet, registrationsToRemove);
        }

        if (this.baselinePointService.hasBrokenBaselinePoints || this.telemetryPointService.hasBrokenPoints) {
          if (this.baselinePointService.hasBrokenBaselinePoints) {
            this.messageService.setError(this.BASELINE_POINTS_FAILED);
          }
          if (this.telemetryPointService.hasBrokenPoints) {
            this.messageService.setError(this.TELEMETRY_POINTS_FAILED);
          }
          this.handleEdit(response.id);
        } else {
          this.messageService.setSuccess(this.SUCCESS);
          this.telemetryPointService.clearBrokenPoints();
          this.baselinePointService.clearBrokenBaselinePoints();
          setTimeout(() => {
            switch (this.selectedDRType.name) {
              case 'Registration':
                this.registrationComponentView.resetSdps();
                this.registrationComponentView.resetDeeplinkerData();
                this.registrationsService.refetchRegistrations();
                break;
              case 'RegistrationSet':
                this.registrationSetService.refetchRegistrationSets();
                break;
            }
            this.router.navigate([`details/${response.id}/view`], {
              queryParams: {
                drType: this.selectedDRType.name,
              },
            });
          }, 2000);
        }
      } catch (e) {
        this.saving = false;
        const errorMessage = e.message || e.error.message;
        console.log({ EDIT_ERROR: e });
        if (errorMessage === 'ERR_BAD_REQUEST') {
          this.messageService.setError(this.BAD_REQUEST);
        } else {
          if (this.selectedDRType.name === 'Registration') {
            this.messageService.setError(this.REG_NOT_UPDATED);
          } else if (this.selectedDRType.name === 'RegistrationSet') {
            this.messageService.setError(this.REG_SET_NOT_UPDATED);
          }
        }
      }
      finally {
        if (savingDialog) {
          savingDialog.componentInstance.changeState('DEFAULT');
        }
      }
    }
  }
  openUpdateDialog() {
    return this.dialog.open(RegistrationDialogComponent, {
      width: '400px',
      data: {
        name: this.registration.name,
      },
      disableClose: true,
    });
  }

  openDeleteDialog(): void {
    this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {
        drType: this.selectedDRType.name,
        id: this.regId,
        isFullReg: this.appData.isFullReg,
        baselinePoints: this.registrationComponentView ? this.registrationComponentView.baselinePoints : null,
        telemetryPoints: this.registrationComponentView ? this.registrationComponentView.points : null,
        rules: this.transformRules,
      },
    });
  }

  private async loadDynamicLists(orgId: string) {
    if (orgId) {
      await this.selectedSpaceService.setOrgSites(orgId);
    }
  }

  handleCancel() {
    this.telemetryPointService.clearBrokenPoints();
    this.baselinePointService.clearBrokenBaselinePoints();
    this.router.navigate([`details/${this.regId}/view`], {
      queryParams: {
        drType: this.selectedDRType.name,
      },
    });
  }

  private async handleEdit(id: string) {
    this.saving = false;
    this.registrationComponentView.resetSdps();
    this.registrationComponentView.resetDeeplinkerData();
    this.registrationsService.refetchRegistrations();
    this.router.navigate([`details/${id}/edit`], {
      queryParams: {
        drType: this.selectedDRType.name,
      },
    });
  }

  isRetired() {
    if (this.registration && this.registration.status) {
      return this.registration.status === 'RETIRED';
    }
  }

  handleError() {
    this.router.navigate([`/`]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
